import React from 'react';
import Button from '../Button';
import { companyContent } from '../../content/index';

const Join = () => {
  const { header, cta } = companyContent.join;

  return (
    <div data-testid="join-container" className="flex flex-col space-y-7">
      <h1 className="text-center md:text-xl md:font-bold font-semibold lg:text-2xl">
        {header}
      </h1>
      <div className="mx-auto mt-7">
        <Button
          className="md:hidden block uppercase"
          variant="inverse"
          text={cta.mobile}
          to="/join"
        />
        <Button
          className="md:block hidden uppercase"
          variant="inverse"
          text={cta.desktop}
          to="/join"
        />
      </div>
    </div>
  );
};

export default Join;
